import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
    connectionSoftwareOptions,
    booleanOptions
} from '../../config/dropdown-options.js';
import SubmitButton from '../common/SubmitButton.jsx';
import useOffices from '../../hooks/useOffices.js';
import { createOfficeFormFields } from '../../config/form-fields.js';
import createOfficeValidation from '../../validation/create-office-validation.js';
import ErrorMessage from '../common/ErrorMessage.jsx';
import SuccessMessage from '../common/SuccessMessage.jsx';
import LoadingSpinner from '../common/LoadingSpinner.jsx';
import ToggleVisibilityButton from '../common/ToggleVisibilityButton.jsx';
import { setOfficeObj } from '../../redux/slices/office-slice.js';
import convertPhoneNumberToNationalFormat from '../../utils/convert-phone-number-to-national.js';
import useUsers from '../../hooks/useUsers.js';

/**
 * CreateUpdateOfficeForm component for rendering the form to create a new office or update an existing one.
 *
 * @component
 * @returns {JSX.Element} - The rendered CreateUpdateOfficeForm component.
 */
const CreateUpdateOfficeForm = ({ objectToUpdate, setShowEdit }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {
        error: officeError,
        loading: officeLoading,
        office,
        createOffice,
        updateOffice
    } = useOffices();

    const {
        error: usersError,
        loading: usersLoading,
        getAllUsers
    } = useUsers();

    const [userDropdownOptions, setUserDropdownOptions] = useState([]);

    if (objectToUpdate) {
        // eslint-disable-next-line no-param-reassign
        objectToUpdate = { ...objectToUpdate };
        // eslint-disable-next-line no-param-reassign
        objectToUpdate.phoneNumber = convertPhoneNumberToNationalFormat(
            objectToUpdate.phoneNumber
        );
        // eslint-disable-next-line no-param-reassign
        objectToUpdate.itPhoneNumber = convertPhoneNumberToNationalFormat(
            objectToUpdate.itPhoneNumber
        );
    }

    const formik = useFormik({
        initialValues: objectToUpdate || createOfficeFormFields,
        validationSchema: createOfficeValidation,
        onSubmit: async (values) => {
            // Take out showPassword and id when submitting
            const { showPassword, id, ...formValues } = values;

            if (objectToUpdate) {
                const updatedOffice = await updateOffice(
                    objectToUpdate.id,
                    formValues,
                    objectToUpdate
                );
                if (updatedOffice) {
                    dispatch(setOfficeObj(updatedOffice));
                    setShowEdit(false);
                }
            } else {
                const newOffice = await createOffice(formValues);
                if (newOffice) {
                    navigate(`/office/${newOffice.id}`);
                }
            }
        }
    });

    /**
     * Handles the toggle of the password visibility.
     */
    const handleTogglePassword = () => {
        formik.setFieldValue('showPassword', !formik.values.showPassword);
    };

    const formatPhoneNumber = (value) => {
        if (!value) return value;

        // Remove all non-numeric characters
        const phoneNumber = value.replace(/[^\d]/g, '');

        const phoneNumberLength = phoneNumber.length;

        if (phoneNumberLength < 4) return phoneNumber;
        if (phoneNumberLength < 7) {
            return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
        }

        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
            3,
            6
        )}-${phoneNumber.slice(6, 10)}`;
    };

    useEffect(() => {
        /**
         * Checks if the error passed back from submission is because the office systematic name is taken.
         * If it is, sets the proper field error
         */
        const callCheckSysName = async () => {
            if (
                officeError &&
                officeError.message === 'Office Systematic Name already exists'
            ) {
                formik.setFieldError(
                    'officeSysName',
                    'That systematic name is taken, please select another'
                );
            }
        };

        callCheckSysName();
    }, [officeError]);

    useEffect(() => {
        const getUsers = async () => {
            let returnedUsers = await getAllUsers();
            returnedUsers = returnedUsers.map((u) => {
                // eslint-disable-next-line no-param-reassign
                u = {
                    value: u.email,
                    label: u.email
                };
                return u;
            });
            setUserDropdownOptions(returnedUsers);
        };

        getUsers();
    }, []);

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <table className="office-table">
                    <tbody>
                        <tr>
                            <td className="office-table-left-cell">
                                <label htmlFor="officeSysName">
                                    Office Systematic Name:
                                </label>
                            </td>
                            <td className="office-table-right-cell">
                                <input
                                    id="officeSysName"
                                    type="text"
                                    className="office-computer-edit-input"
                                    style={{
                                        color: formik.errors.officeSysName
                                            ? 'red'
                                            : 'black'
                                    }}
                                    {...formik.getFieldProps('officeSysName')}
                                />
                            </td>
                            {formik.touched.officeSysName &&
                            formik.errors.officeSysName ? (
                                <ErrorMessage
                                    message={formik.errors.officeSysName}
                                />
                            ) : null}
                        </tr>
                        <tr>
                            <td className="office-table-left-cell">
                                <label htmlFor="notes">Notes:</label>
                            </td>
                            <td className="office-table-right-cell">
                                <textarea
                                    id="notes"
                                    className="office-computer-edit-input"
                                    style={{
                                        height: '10em',
                                        paddingTop: '.5em'
                                    }}
                                    {...formik.getFieldProps('notes')}
                                />
                            </td>
                            {formik.touched.notes && formik.errors.notes ? (
                                <ErrorMessage message={formik.errors.notes} />
                            ) : null}
                        </tr>
                        <tr>
                            <td className="office-table-left-cell">
                                <label htmlFor="officeName">Office Name:</label>
                            </td>
                            <td className="office-table-right-cell">
                                <input
                                    id="officeName"
                                    type="text"
                                    className="office-computer-edit-input"
                                    {...formik.getFieldProps('officeName')}
                                />
                            </td>
                            {formik.touched.officeName &&
                            formik.errors.officeName ? (
                                <ErrorMessage
                                    autofocus
                                    message={formik.errors.officeName}
                                />
                            ) : null}
                        </tr>
                        <tr>
                            <td className="office-table-left-cell">
                                <label htmlFor="phoneNumber">
                                    Office Phone Number:
                                </label>
                            </td>
                            <td className="office-table-right-cell">
                                <input
                                    id="phoneNumber"
                                    type="text"
                                    className="office-computer-edit-input"
                                    {...formik.getFieldProps('phoneNumber')}
                                    onChange={(e) => {
                                        formik.setFieldValue(
                                            'phoneNumber',
                                            formatPhoneNumber(e.target.value)
                                        );
                                    }}
                                />
                            </td>
                            {formik.touched.phoneNumber &&
                            formik.errors.phoneNumber ? (
                                <ErrorMessage
                                    message={formik.errors.phoneNumber}
                                />
                            ) : null}
                        </tr>
                        <tr>
                            <td className="office-table-left-cell">
                                <label htmlFor="officeEmail">
                                    Office Email:
                                </label>
                            </td>
                            <td className="office-table-right-cell">
                                <input
                                    id="officeEmail"
                                    type="text"
                                    className="office-computer-edit-input"
                                    {...formik.getFieldProps('officeEmail')}
                                />
                            </td>
                            {formik.touched.officeEmail &&
                            formik.errors.officeEmail ? (
                                <ErrorMessage
                                    message={formik.errors.officeEmail}
                                />
                            ) : null}
                        </tr>
                        <tr>
                            <td className="office-table-left-cell">
                                <label htmlFor="poc">
                                    Point of contact (POC):
                                </label>
                            </td>
                            <td className="office-table-right-cell">
                                <input
                                    id="poc"
                                    type="text"
                                    className="office-computer-edit-input"
                                    {...formik.getFieldProps('poc')}
                                />
                            </td>
                            {formik.touched.poc && formik.errors.poc ? (
                                <ErrorMessage message={formik.errors.poc} />
                            ) : null}
                        </tr>
                        <tr>
                            <td className="office-table-left-cell">
                                <label htmlFor="itName">IT Name:</label>
                            </td>
                            <td className="office-table-right-cell">
                                <input
                                    id="itName"
                                    type="text"
                                    className="office-computer-edit-input"
                                    {...formik.getFieldProps('itName')}
                                />
                            </td>
                            {formik.touched.itName && formik.errors.itName ? (
                                <ErrorMessage message={formik.errors.itName} />
                            ) : null}
                        </tr>
                        <tr>
                            <td className="office-table-left-cell">
                                <label htmlFor="itEmail">IT Email:</label>
                            </td>
                            <td className="office-table-right-cell">
                                <input
                                    id="itEmail"
                                    type="text"
                                    className="office-computer-edit-input"
                                    {...formik.getFieldProps('itEmail')}
                                />
                            </td>
                            {formik.touched.itEmail && formik.errors.itEmail ? (
                                <ErrorMessage message={formik.errors.itEmail} />
                            ) : null}
                        </tr>
                        <tr>
                            <td className="office-table-left-cell">
                                <label htmlFor="itPhoneNumber">
                                    IT Phone Number:
                                </label>
                            </td>
                            <td className="office-table-right-cell">
                                <input
                                    id="itPhoneNumber"
                                    type="text"
                                    className="office-computer-edit-input"
                                    {...formik.getFieldProps('itPhoneNumber')}
                                    onChange={(e) => {
                                        formik.setFieldValue(
                                            'itPhoneNumber',
                                            formatPhoneNumber(e.target.value)
                                        );
                                    }}
                                />
                            </td>
                            {formik.touched.itPhoneNumber &&
                            formik.errors.itPhoneNumber ? (
                                <ErrorMessage
                                    message={formik.errors.itPhoneNumber}
                                />
                            ) : null}
                        </tr>
                        <tr>
                            <td className="office-table-left-cell">
                                <label htmlFor="production">Production:</label>
                            </td>
                            <td className="office-table-right-cell">
                                <input
                                    id="production"
                                    type="text"
                                    className="office-computer-edit-input"
                                    {...formik.getFieldProps('production')}
                                />
                            </td>
                            {formik.touched.production &&
                            formik.errors.production ? (
                                <ErrorMessage
                                    message={formik.errors.production}
                                />
                            ) : null}
                        </tr>
                        {objectToUpdate && (
                            <>
                                <tr>
                                    <td className="office-table-left-cell">
                                        <label htmlFor="installTech">
                                            Install Tech:
                                        </label>
                                    </td>
                                    <td className="office-table-right-cell">
                                        {usersLoading && <LoadingSpinner />}
                                        {usersError && (
                                            <ErrorMessage
                                                message={usersError.message}
                                            />
                                        )}
                                        {userDropdownOptions.length > 0 && (
                                            <Select
                                                id="installTech"
                                                name="installTech"
                                                options={userDropdownOptions}
                                                value={userDropdownOptions.find(
                                                    (option) =>
                                                        option.value ===
                                                        formik.values
                                                            .installTech
                                                )}
                                                onChange={(selectedOption) =>
                                                    formik.setFieldValue(
                                                        'installTech',
                                                        selectedOption
                                                            ? selectedOption.value
                                                            : null
                                                    )
                                                }
                                            />
                                        )}
                                    </td>
                                    {formik.touched.installTech &&
                                    formik.errors.installTech ? (
                                        <ErrorMessage
                                            message={formik.errors.installTech}
                                        />
                                    ) : null}
                                </tr>
                                <tr>
                                    <td className="office-table-left-cell">
                                        <label htmlFor="installDate">
                                            Install Date:
                                        </label>
                                    </td>
                                    <td className="office-table-right-cell">
                                        <input
                                            id="installDate"
                                            type="date"
                                            className="office-computer-edit-input"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            defaultValue={
                                                formik.values.installDate
                                                    ? new Date(
                                                          formik.values.installDate
                                                      )
                                                          .toISOString()
                                                          .substring(0, 10)
                                                    : null
                                            }
                                        />
                                    </td>
                                    {formik.touched.installDate &&
                                    formik.errors.installDate ? (
                                        <ErrorMessage
                                            message={formik.errors.installDate}
                                        />
                                    ) : null}
                                </tr>
                            </>
                        )}

                        <tr>
                            <td className="office-table-left-cell">
                                <label htmlFor="numOfDBConnections">
                                    Number of DB Connections:
                                </label>
                            </td>
                            <td className="office-table-right-cell">
                                <input
                                    id="numOfDBConnections"
                                    type="number"
                                    className="office-computer-edit-input"
                                    {...formik.getFieldProps(
                                        'numOfDBConnections'
                                    )}
                                />
                            </td>
                            {formik.touched.numOfDBConnections &&
                            formik.errors.numOfDBConnections ? (
                                <ErrorMessage
                                    message={formik.errors.numOfDBConnections}
                                />
                            ) : null}
                        </tr>
                        <tr>
                            <td className="office-table-left-cell">
                                <label htmlFor="numOfIVConnections">
                                    Number of IV Connections:
                                </label>
                            </td>
                            <td className="office-table-right-cell">
                                <input
                                    id="numOfIVConnections"
                                    type="number"
                                    className="office-computer-edit-input"
                                    {...formik.getFieldProps(
                                        'numOfIVConnections'
                                    )}
                                />
                            </td>
                            {formik.touched.numOfIVConnections &&
                            formik.errors.numOfIVConnections ? (
                                <ErrorMessage
                                    message={formik.errors.numOfIVConnections}
                                />
                            ) : null}
                        </tr>
                        <tr>
                            <td className="office-table-left-cell">
                                <label htmlFor="numOfPPConnections">
                                    Number of PB Connections:
                                </label>
                            </td>
                            <td className="office-table-right-cell">
                                <input
                                    id="numOfPPConnections"
                                    type="number"
                                    className="office-computer-edit-input"
                                    {...formik.getFieldProps(
                                        'numOfPPConnections'
                                    )}
                                />
                            </td>
                            {formik.touched.numOfPPConnections &&
                            formik.errors.numOfPPConnections ? (
                                <ErrorMessage
                                    message={formik.errors.numOfPPConnections}
                                />
                            ) : null}
                        </tr>
                        <tr>
                            <td className="office-table-left-cell">
                                <label htmlFor="numOfFSConnections">
                                    Number of FS Connections:
                                </label>
                            </td>
                            <td className="office-table-right-cell">
                                <input
                                    id="numOfFSConnections"
                                    type="number"
                                    className="office-computer-edit-input"
                                    {...formik.getFieldProps(
                                        'numOfFSConnections'
                                    )}
                                />
                            </td>
                            {formik.touched.numOfFSConnections &&
                            formik.errors.numOfFSConnections ? (
                                <ErrorMessage
                                    message={formik.errors.numOfFSConnections}
                                />
                            ) : null}
                        </tr>
                        <tr>
                            <td className="office-table-left-cell">
                                <label htmlFor="eobPath">EOB Path:</label>
                            </td>
                            <td className="office-table-right-cell">
                                <input
                                    id="eobPath"
                                    type="text"
                                    className="office-computer-edit-input"
                                    {...formik.getFieldProps('eobPath')}
                                />
                            </td>
                            {formik.touched.eobPath && formik.errors.eobPath ? (
                                <ErrorMessage message={formik.errors.eobPath} />
                            ) : null}
                        </tr>
                        <tr>
                            <td className="office-table-left-cell">
                                <label htmlFor="connectionSoftware">
                                    Connection Software:
                                </label>
                            </td>
                            <td className="office-table-right-cell">
                                <Select
                                    id="connectionSoftware"
                                    name="connectionSoftware"
                                    options={connectionSoftwareOptions}
                                    value={connectionSoftwareOptions.find(
                                        (option) =>
                                            option.value ===
                                            formik.values.connectionSoftware
                                    )}
                                    onChange={(selectedOption) =>
                                        formik.setFieldValue(
                                            'connectionSoftware',
                                            selectedOption
                                                ? selectedOption.value
                                                : null
                                        )
                                    }
                                />
                            </td>
                            {formik.touched.connectionSoftware &&
                            formik.errors.connectionSoftware ? (
                                <ErrorMessage
                                    message={formik.errors.connectionSoftware}
                                />
                            ) : null}
                        </tr>
                        <tr>
                            <td className="office-table-left-cell">
                                <label htmlFor="addendumOnFile">
                                    Unique VPN Addendum on File:
                                </label>
                            </td>
                            <td className="office-table-right-cell">
                                <Select
                                    id="addendumOnFile"
                                    name="addendumOnFile"
                                    options={booleanOptions}
                                    value={booleanOptions.find(
                                        (option) =>
                                            option.value ===
                                            formik.values.uniqueVPN
                                                .addendumOnFile
                                    )}
                                    onChange={(selectedOption) =>
                                        formik.setFieldValue(
                                            'uniqueVPN.addendumOnFile',
                                            selectedOption.value
                                        )
                                    }
                                />
                            </td>
                            {formik.touched['uniqueVPN.addendumOnFile'] &&
                            formik.errors['uniqueVPN.addendumOnFile'] ? (
                                <ErrorMessage
                                    message={
                                        formik.errors[
                                            'uniqueVPN.addendumOnFile'
                                        ]
                                    }
                                />
                            ) : null}
                        </tr>
                        <tr>
                            <td className="office-table-left-cell">
                                <label htmlFor="dentalSoftware">
                                    Dental Software:
                                </label>
                            </td>
                            <td className="office-table-right-cell">
                                <input
                                    id="dentalSoftware"
                                    type="text"
                                    className="office-computer-edit-input"
                                    {...formik.getFieldProps('dentalSoftware')}
                                />
                            </td>
                            {formik.touched.dentalSoftware &&
                            formik.errors.dentalSoftware ? (
                                <ErrorMessage
                                    message={formik.errors.dentalSoftware}
                                />
                            ) : null}
                        </tr>
                        <tr>
                            <td className="office-table-left-cell">
                                <label htmlFor="dsUsername">
                                    Dental Software Username
                                </label>
                            </td>
                            <td className="office-table-right-cell">
                                <input
                                    id="dsUsername"
                                    type="text"
                                    className="office-computer-edit-input"
                                    {...formik.getFieldProps('dsUsername')}
                                />
                            </td>
                            {formik.touched.dsUsername &&
                            formik.errors.dsUsername ? (
                                <ErrorMessage
                                    message={formik.errors.dsUsername}
                                />
                            ) : null}
                        </tr>
                        <tr>
                            <td className="office-table-left-cell">
                                <label htmlFor="dsPassword">
                                    Dental Software Password:
                                </label>
                            </td>
                            <td className="office-table-right-cell-pwd">
                                <input
                                    id="dsPassword"
                                    type={
                                        formik.values.showPassword
                                            ? 'text'
                                            : 'password'
                                    }
                                    className="office-computer-edit-input"
                                    {...formik.getFieldProps('dsPassword')}
                                />
                                <ToggleVisibilityButton
                                    condition={formik.values.showPassword}
                                    onClick={handleTogglePassword}
                                />
                            </td>
                            {formik.touched.dsPassword &&
                            formik.errors.dsPassword ? (
                                <ErrorMessage
                                    message={formik.errors.dsPassword}
                                />
                            ) : null}
                        </tr>
                        <tr>
                            <td className="office-table-left-cell">
                                <label htmlFor="dsUsername2">
                                    Second Dental Software Username:
                                </label>
                            </td>
                            <td className="office-table-right-cell">
                                <input
                                    id="dsUsername2"
                                    type="text"
                                    className="office-computer-edit-input"
                                    {...formik.getFieldProps('dsUsername2')}
                                />
                            </td>
                            {formik.touched.dsUsername2 &&
                            formik.errors.dsUsername2 ? (
                                <ErrorMessage
                                    message={formik.errors.dsUsername2}
                                />
                            ) : null}
                        </tr>
                        <tr>
                            <td className="office-table-left-cell">
                                <label htmlFor="dsPassword2">
                                    Second Dental Software Password:
                                </label>
                            </td>
                            <td className="office-table-right-cell-pwd">
                                <input
                                    id="dsPassword2"
                                    type={
                                        formik.values.showPassword
                                            ? 'text'
                                            : 'password'
                                    }
                                    className="office-computer-edit-input"
                                    {...formik.getFieldProps('dsPassword2')}
                                />
                                <ToggleVisibilityButton
                                    condition={formik.values.showPassword}
                                    onClick={handleTogglePassword}
                                />
                            </td>
                            {formik.touched.dsPassword2 &&
                            formik.errors.dsPassword2 ? (
                                <ErrorMessage
                                    message={formik.errors.dsPassword2}
                                />
                            ) : null}
                        </tr>
                        <tr>
                            <td className="office-table-left-cell">
                                <label htmlFor="dsURL">
                                    Dental Software URL:
                                </label>
                            </td>
                            <td className="office-table-right-cell">
                                <input
                                    id="dsURL"
                                    type="text"
                                    className="office-computer-edit-input"
                                    {...formik.getFieldProps('dsURL')}
                                />
                            </td>
                            {formik.touched.dsURL && formik.errors.dsURL ? (
                                <ErrorMessage message={formik.errors.dsURL} />
                            ) : null}
                        </tr>
                        <tr>
                            <td className="office-table-left-cell">
                                <label htmlFor="eClaims">eClaims:</label>
                            </td>
                            <td className="office-table-right-cell">
                                <input
                                    id="eClaims"
                                    type="text"
                                    className="office-computer-edit-input"
                                    {...formik.getFieldProps('eClaims')}
                                />
                            </td>
                            {formik.touched.eClaims && formik.errors.eClaims ? (
                                <ErrorMessage message={formik.errors.eClaims} />
                            ) : null}
                        </tr>
                        <tr>
                            <td className="office-table-left-cell">
                                <label htmlFor="eClaimsUsername">
                                    eClaims Username:
                                </label>
                            </td>
                            <td className="office-table-right-cell">
                                <input
                                    id="eClaimsUsername"
                                    type="text"
                                    className="office-computer-edit-input"
                                    {...formik.getFieldProps('eClaimsUsername')}
                                />
                            </td>
                            {formik.touched.eClaimsUsername &&
                            formik.errors.eClaimsUsername ? (
                                <ErrorMessage
                                    message={formik.errors.eClaimsUsername}
                                />
                            ) : null}
                        </tr>
                        <tr>
                            <td className="office-table-left-cell">
                                <label htmlFor="eClaimsPassword">
                                    eClaims Password:
                                </label>
                            </td>
                            <td className="office-table-right-cell-pwd">
                                <input
                                    id="eClaimsPassword"
                                    type={
                                        formik.values.showPassword
                                            ? 'text'
                                            : 'password'
                                    }
                                    className="office-computer-edit-input"
                                    {...formik.getFieldProps('eClaimsPassword')}
                                />
                                <ToggleVisibilityButton
                                    condition={formik.values.showPassword}
                                    onClick={handleTogglePassword}
                                />
                            </td>
                            {formik.touched.eClaimsPassword &&
                            formik.errors.eClaimsPassword ? (
                                <ErrorMessage
                                    message={formik.errors.eClaimsPassword}
                                />
                            ) : null}
                        </tr>
                        <tr>
                            <td className="office-table-left-cell">
                                <label htmlFor="eClaimsInstructions">
                                    eClaims Instructions:
                                </label>
                            </td>
                            <td className="office-table-right-cell">
                                <input
                                    id="eClaimsInstructions"
                                    type="text"
                                    className="office-computer-edit-input"
                                    {...formik.getFieldProps(
                                        'eClaimsInstructions'
                                    )}
                                />
                            </td>
                            {formik.touched.eClaimsInstructions &&
                            formik.errors.eClaimsInstructions ? (
                                <ErrorMessage
                                    message={formik.errors.eClaimsInstructions}
                                />
                            ) : null}
                        </tr>
                        <tr>
                            <td className="office-table-left-cell">
                                <label htmlFor="attachments">
                                    Attachments:
                                </label>
                            </td>
                            <td className="office-table-right-cell">
                                <input
                                    id="attachments"
                                    type="text"
                                    className="office-computer-edit-input"
                                    {...formik.getFieldProps('attachments')}
                                />
                            </td>
                            {formik.touched.attachments &&
                            formik.errors.attachments ? (
                                <ErrorMessage
                                    message={formik.errors.attachments}
                                />
                            ) : null}
                        </tr>
                        <tr>
                            <td className="office-table-left-cell">
                                <label htmlFor="xrays">X-Rays:</label>
                            </td>
                            <td className="office-table-right-cell">
                                <input
                                    id="xrays"
                                    type="text"
                                    className="office-computer-edit-input"
                                    {...formik.getFieldProps('xrays')}
                                />
                            </td>
                            {formik.touched.xrays && formik.errors.xrays ? (
                                <ErrorMessage message={formik.errors.xrays} />
                            ) : null}
                        </tr>
                        <tr>
                            <td className="office-table-left-cell">
                                <label htmlFor="pdfPrinter">PDF Printer:</label>
                            </td>
                            <td className="office-table-right-cell">
                                <input
                                    id="pdfPrinter"
                                    type="text"
                                    className="office-computer-edit-input"
                                    {...formik.getFieldProps('pdfPrinter')}
                                />
                            </td>
                            {formik.touched.pdfPrinter &&
                            formik.errors.pdfPrinter ? (
                                <ErrorMessage
                                    message={formik.errors.pdfPrinter}
                                />
                            ) : null}
                        </tr>
                        <tr>
                            <td className="office-table-left-cell">
                                <label htmlFor="backupTimes">
                                    Backup Times:
                                </label>
                            </td>
                            <td className="office-table-right-cell">
                                <input
                                    id="backupTimes"
                                    type="text"
                                    className="office-computer-edit-input"
                                    {...formik.getFieldProps('backupTimes')}
                                />
                            </td>
                            {formik.touched.backupTimes &&
                            formik.errors.backupTimes ? (
                                <ErrorMessage
                                    message={formik.errors.backupTimes}
                                />
                            ) : null}
                        </tr>
                        <tr>
                            <td className="office-table-left-cell">
                                <label htmlFor="firewallInfo">
                                    Firewall Info:
                                </label>
                            </td>
                            <td className="office-table-right-cell">
                                <input
                                    id="firewallInfo"
                                    type="text"
                                    className="office-computer-edit-input"
                                    {...formik.getFieldProps('firewallInfo')}
                                />
                            </td>
                            {formik.touched.firewallInfo &&
                            formik.errors.firewallInfo ? (
                                <ErrorMessage
                                    message={formik.errors.firewallInfo}
                                />
                            ) : null}
                        </tr>
                        <tr>
                            <td className="office-table-left-cell">
                                <label htmlFor="practiceBoosterUsername">
                                    Practice Booster Username:
                                </label>
                            </td>
                            <td className="office-table-right-cell">
                                <input
                                    id="practiceBoosterUsername"
                                    type="text"
                                    className="office-computer-edit-input"
                                    {...formik.getFieldProps(
                                        'practiceBoosterUsername'
                                    )}
                                />
                            </td>
                            {formik.touched.practiceBoosterUsername &&
                            formik.errors.practiceBoosterUsername ? (
                                <ErrorMessage
                                    message={
                                        formik.errors.practiceBoosterUsername
                                    }
                                />
                            ) : null}
                        </tr>
                        <tr>
                            <td className="office-table-left-cell">
                                <label htmlFor="practiceBoosterPassword">
                                    Practice Booster Password:
                                </label>
                            </td>
                            <td className="office-table-right-cell-pwd">
                                <input
                                    id="practiceBoosterPassword"
                                    type={
                                        formik.values.showPassword
                                            ? 'text'
                                            : 'password'
                                    }
                                    className="office-computer-edit-input"
                                    {...formik.getFieldProps(
                                        'practiceBoosterPassword'
                                    )}
                                />
                                <ToggleVisibilityButton
                                    condition={formik.values.showPassword}
                                    onClick={handleTogglePassword}
                                />
                            </td>

                            {formik.touched.practiceBoosterPassword &&
                            formik.errors.practiceBoosterPassword ? (
                                <ErrorMessage
                                    message={
                                        formik.errors.practiceBoosterPassword
                                    }
                                />
                            ) : null}
                        </tr>
                        <tr>
                            <td className="office-table-left-cell">
                                <label htmlFor="isKoc">Is Auth for KOC:</label>
                            </td>
                            <td className="office-table-right-cell">
                                <Select
                                    id="isKoc"
                                    name="isKoc"
                                    options={booleanOptions}
                                    value={booleanOptions.find(
                                        (option) =>
                                            option.value === formik.values.isKoc
                                    )}
                                    onChange={(selectedOption) =>
                                        formik.setFieldValue(
                                            'isKoc',
                                            selectedOption.value
                                        )
                                    }
                                />
                            </td>
                            {formik.touched.isKoc && formik.errors.isKoc ? (
                                <ErrorMessage message={formik.errors.isKoc} />
                            ) : null}
                        </tr>
                        <tr>
                            <td className="office-table-left-cell">
                                <label htmlFor="isCopilotOffice">
                                    Is Copilot Office?:
                                </label>
                            </td>
                            <td className="office-table-right-cell">
                                <Select
                                    id="isCopilotOffice"
                                    name="isCopilotOffice"
                                    options={booleanOptions}
                                    value={booleanOptions.find(
                                        (option) =>
                                            option.value ===
                                            formik.values.isCopilotOffice
                                    )}
                                    onChange={(selectedOption) =>
                                        formik.setFieldValue(
                                            'isCopilotOffice',
                                            selectedOption.value
                                        )
                                    }
                                />
                            </td>
                            {formik.touched.isCopilotOffice &&
                            formik.errors.isCopilotOffice ? (
                                <ErrorMessage
                                    message={formik.errors.isCopilotOffice}
                                />
                            ) : null}
                        </tr>
                        <tr>
                            <td className="office-table-left-cell">
                                <label htmlFor="copilotServerName">
                                    Copilot Server Name:
                                </label>
                            </td>
                            <td className="office-table-right-cell">
                                <input
                                    id="copilotServerName"
                                    type="text"
                                    className="office-computer-edit-input"
                                    {...formik.getFieldProps(
                                        'copilotServerName'
                                    )}
                                />
                            </td>
                            {formik.touched.copilotServerName &&
                            formik.errors.copilotServerName ? (
                                <ErrorMessage
                                    message={formik.errors.copilotServerName}
                                />
                            ) : null}
                        </tr>
                        <tr>
                            <td className="office-table-left-cell">
                                <label htmlFor="copilotServerUsername">
                                    Copilot Server Username:
                                </label>
                            </td>
                            <td className="office-table-right-cell">
                                <input
                                    id="copilotServerUsername"
                                    type="text"
                                    className="office-computer-edit-input"
                                    {...formik.getFieldProps(
                                        'copilotServerUsername'
                                    )}
                                />
                            </td>
                            {formik.touched.copilotServerUsername &&
                            formik.errors.copilotServerUsername ? (
                                <ErrorMessage
                                    message={
                                        formik.errors.copilotServerUsername
                                    }
                                />
                            ) : null}
                        </tr>
                        <tr>
                            <td className="office-table-left-cell">
                                <label htmlFor="copilotServerPassword">
                                    Copilot Server Password:
                                </label>
                            </td>
                            <td className="office-table-right-cell-pwd">
                                <input
                                    id="copilotServerPassword"
                                    type={
                                        formik.values.showPassword
                                            ? 'text'
                                            : 'password'
                                    }
                                    className="office-computer-edit-input"
                                    {...formik.getFieldProps(
                                        'copilotServerPassword'
                                    )}
                                />
                                <ToggleVisibilityButton
                                    condition={formik.values.showPassword}
                                    onClick={handleTogglePassword}
                                />
                            </td>
                            {formik.touched.copilotServerPassword &&
                            formik.errors.copilotServerPassword ? (
                                <ErrorMessage
                                    message={
                                        formik.errors.copilotServerPassword
                                    }
                                />
                            ) : null}
                        </tr>
                        <tr>
                            <td
                                style={{
                                    borderRight: '1px #DCDCDC solid',
                                    padding: '.5em'
                                }}>
                                <label htmlFor="copilotNotes">
                                    Copilot Notes:
                                </label>
                            </td>
                            <td style={{ padding: '.5em' }}>
                                <textarea
                                    id="copilotNotes"
                                    className="office-computer-edit-input"
                                    style={{
                                        height: '10em',
                                        paddingTop: '.5em'
                                    }}
                                    {...formik.getFieldProps('copilotNotes')}
                                />
                            </td>
                            {formik.touched.copilotNotes &&
                            formik.errors.copilotNotes ? (
                                <ErrorMessage
                                    message={formik.errors.copilotNotes}
                                />
                            ) : null}
                        </tr>
                    </tbody>
                </table>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-evenly'
                    }}>
                    <SubmitButton
                        label="Submit"
                        style={{ marginLeft: '30px' }}
                    />
                    <button
                        className="submit-btn"
                        onClick={() => setShowEdit(false)}>
                        Cancel
                    </button>
                </div>
            </form>
            <br />
            <br />
            {officeLoading && <LoadingSpinner />}
            {Object.keys(formik.errors).length !== 0 &&
            Object.keys(formik.touched).length !== 0 ? (
                <ErrorMessage message="There are form errors above, please fix." />
            ) : null}
            {officeError &&
            officeError.message !== 'Office Systematic Name already exists' ? (
                <ErrorMessage message={officeError.message} />
            ) : null}
            {office && <SuccessMessage message="Office successfully created" />}
        </>
    );
};

CreateUpdateOfficeForm.propTypes = {
    objectToUpdate: PropTypes.object,
    setShowEdit: PropTypes.func
};

export default CreateUpdateOfficeForm;
