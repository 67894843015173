import React from 'react';
import { useSelector } from 'react-redux';
import {
    selectComputers,
    selectOfficeObj
} from '../../redux/slices/office-slice.js';
import CopyComputerInfo from './CopyComputerInfo.jsx';

/**
 * Component for displaying office information.
 *
 * @returns {JSX.Element} JSX element representing the Office Information.
 */
const CopyOfficeInfo = () => {
    const computers = useSelector(selectComputers);
    const officeObj = useSelector(selectOfficeObj);

    let kocDate = null;

    if (officeObj.kocDate) {
        const thisKoc = new Date(officeObj.kocDate);
        const year = thisKoc.getFullYear();
        const month = thisKoc.getMonth() + 1;
        const day = thisKoc.getDate();

        kocDate = `${month}/${day}/${year}`;
    }

    return (
        <div style={{ marginLeft: '30px' }}>
            <p>
                Number of separate connections DB:{' '}
                {officeObj.numOfDBConnections}&nbsp; IV:{' '}
                {officeObj.numOfIVConnections}&nbsp; PB:{' '}
                {officeObj.numOfPPConnections}&nbsp; FS:{' '}
                {officeObj.numOfFSConnections}
            </p>
            <p>SC Connection Type: {officeObj.connectionSoftware}</p>
            {computers.map((computer) => {
                if (!computer.isITOnly)
                    return (
                        <CopyComputerInfo
                            key={computer._id}
                            systematicName={computer.computerSysName}
                            OS={computer.OS}
                            location={computer.location}
                            credentials={computer.credentials}
                            isAvInstalled={computer.avInstalled}
                            avName={computer.avName}
                        />
                    );
                return <></>;
            })}
            <p className="copy-and-paste-text">EOB Path: {officeObj.eobPath}</p>
            <p className="copy-and-paste-text">
                PMS Software: {officeObj.dentalSoftware}
            </p>
            <p className="copy-and-paste-text">
                PMS username 1: {officeObj.dsUsername}
            </p>
            <p className="copy-and-paste-text">
                PMS password 1: {officeObj.dsPassword}
            </p>
            {officeObj.dsUsername2 && (
                <>
                    <p className="copy-and-paste-text">
                        PMS software username 2: {officeObj.dsUsername2}
                    </p>
                    <p className="copy-and-paste-text">
                        PMS software password 2: {officeObj.dsPassword2}
                    </p>
                </>
            )}
            {officeObj.connectionSoftware === 'cloud' ? (
                <p className="copy-and-paste-text">
                    Cloud PMS URL: {officeObj.dsURL}
                </p>
            ) : (
                <p className="copy-and-paste-text">PMS is not cloud based</p>
            )}
            <p className="copy-and-paste-text">
                eClaims Program: {officeObj.eClaims}
            </p>
            <p className="copy-and-paste-text">
                User: {officeObj.eClaimsUsername}
            </p>
            <p className="copy-and-paste-text">
                Password: {officeObj.eClaimsPassword}
            </p>
            <p className="copy-and-paste-text">
                Is eClaims properly functioning?
            </p>
            <p className="copy-and-paste-text">
                eAttachment Program: {officeObj.attachments}
            </p>
            <p className="copy-and-paste-text">
                X-Rays Program: {officeObj.xrays}
            </p>
            <p className="copy-and-paste-text">
                Is the X-Rays program functioning properly?
            </p>
            <p className="copy-and-paste-text">
                PDF Printer: {officeObj.pdfPrinter}
            </p>
            <p className="copy-and-paste-text">
                What time do backups occur?: {officeObj.backupTimes}
            </p>
            <p style={{ marginTop: '0em' }}>
                Firewall Info: {officeObj.firewallInfo}
            </p>
            <p className="copy-and-paste-text">
                Practice Booster Username: {officeObj.practiceBoosterUsername}
            </p>
            <p className="copy-and-paste-text">
                Practice Booster Password: {officeObj.practiceBoosterPassword}
            </p>
            <p className="copy-and-paste-text">
                Zilla Number:{' '}
                {officeObj.twilioActivated ? officeObj.twilioNumber : 'N/A'}
            </p>
            <p className="copy-and-paste-text">
                Caller ID Verified: {officeObj.callerIdVerified ? 'Yes' : 'No'}
            </p>
            {officeObj.connectionSoftware === 'Cloud' && (
                <p className="copy-and-paste-text">Is sharefile set up?</p>
            )}
            {officeObj.kocDate ? (
                <p className="copy-and-paste-text">
                    Date Authorized for KOC: {kocDate}
                </p>
            ) : (
                <p className="copy-and-paste-text">
                    Office is not authorized for KOC
                </p>
            )}
            <p className="copy-and-paste-text">
                Is Copilot Office: {officeObj.isCopilotOffice ? 'Yes' : 'No'}
            </p>
        </div>
    );
};

export default CopyOfficeInfo;
